(function() {

  var domains = {
    // NL
    'nl_NL' : '/nl/nl',
    'en_NL' : '/nl/en'
  };

  var publicDomains = [
    // NL
    'nl_NL',
  ];

  var site_default_lang = 'nl_NL';

  // This is for either proxy prefixes or if the drupal
  // dir lives within a subdir.
  var inert_prefixes = ['/OneLinkOPE'];

  var cookie_tools = {

    set: function (name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },

    get: function(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    },

    remove: function(name) {
      this.set(name, "", -1);
    },
  }

  function check_locale_cookie(domains, default_lang) {
    var locale_cookie = cookie_tools.get('LOCALE');
    // cookie isn't supported, or is default language. Force to default.
    if (domains.indexOf(locale_cookie) === -1 && !cookie_tools.get('SET_LOCALE_BYPASS')) {
      cookie_tools.set('LOCALE', default_lang);
    }
  }

  function check_locale_prefix(domains, default_lang) {
    var path = window.location.pathname;
    var protocol = window.location.protocol;
    var host = window.location.host;
    var search = window.location.search;
    var hash = window.location.hash;

    var locale_cookie = cookie_tools.get('LOCALE');

    var active_path = path;
    var inert_path = '';

    /*
     * We split up path into inert and active paths.
     * This is largely for t.com OPE tool but can be used
     * for things like subdir installs.
     */
    for (var i = 0; i < inert_prefixes.length; i++) {
      var test_inert_prefix = inert_prefixes[i];
      if (_is_prefixed(path, test_inert_prefix)) {
        inert_path = test_inert_prefix;
        active_path = _remove_prefix(path, test_inert_prefix);
      }
    }

    if (!locale_cookie) {
      // no cookie or default lang. bail
      if (!default_lang) {
        return;
      }
      // set the default cookie and continue
      locale_cookie = default_lang;
      cookie_tools.set('LOCALE', default_lang);
    }

    var is_dev = window.location.href.indexOf('esteelauder.benelux') !== -1;
    var is_pg = active_path.indexOf('.tmpl') !== -1;
    // skip tmpl on dev
    // pg on lower envs always using the en prefix for form actions
    // onelink proxy will rewrite the form action on production.
    if(is_dev && is_pg) {
      return;
    }

    var domainPath = domains[locale_cookie];
    if (!domainPath) {
      // no domain path for this locale
      return;
    }

    var prefix_length = domainPath.length;
    var path_test = active_path.substring(0, prefix_length);

    if (domainPath.toLowerCase() === path_test.toLowerCase()) {
      // prefix already set correctly
      return;
    }

    // remove current locale prefix from path
    var current_prefix_locale = get_prefixed_locale(domains, active_path);
    if (current_prefix_locale) {
      var current_prefix = domains[current_prefix_locale];
      active_path = _remove_prefix(active_path, current_prefix);
    }

    var destination_url = protocol + '//' + host + inert_path + domainPath + active_path + search + hash;

    // good to redirect
    window.location.href = destination_url;
  }

  function get_prefixed_locale(domains, path) {
    for (var langcode in domains) {
      if (!domains.hasOwnProperty(langcode)) {
        continue;
      }
      var test_prefix = domains[langcode];
      if (_is_prefixed(path, test_prefix)) {
        return langcode;
      }
    }
  }

  function _remove_prefix(path, prefix) {
    path = path.substring(prefix.length);
    return path
  }

  function _is_prefixed(path, prefix) {
    var test_path = path.substring(0, prefix.length);
    return test_path == prefix;
  }

  function generate_default_locale() {
    // onelink On Page Editor.
    var is_ope =  window.location.href.indexOf('onelink-translations.com') !== -1;
    if (is_ope) {
      var lang = window.location.hostname.substr(0,2);
      var country = window.location.hostname.substr(2,2).toUpperCase();
      var locale_cookie = lang + '_' + country;
      return locale_cookie;
    }
    return site_default_lang;
  }

  var default_lang = generate_default_locale();
  check_locale_cookie(publicDomains, default_lang);
  check_locale_prefix(domains, default_lang);
})();
